import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Col, Row, Button} from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import classnames from 'classnames';
import ComplexTable from '../../components/Table/ComplexTable';
import { IUserState } from '../../store/user/types';
import { IContactsState } from '../../store/contacts/types';
import {
  getAccountContacts, setContactsSearchString, toggleContactsLoading, resetContactsStore, populateContactTypes,
} from '../../store/contacts/actions';
import Section from '../../components/Section';
import SearchInput from '../../components/Inputs/SearchInput';
import { IContactState } from '../../store/contact/types';
import ButtonLink from '../../components/Buttons/ButtonLink';
import Search from '../../components/SvgIcons/SearchIcon';
import SimpleLoader from '../../components/SimpleLoader';
import Refresh from '../../components/SvgIcons/Refresh';
import { checkPermission } from '../../utils/Auth/AuthService';
import { ACCOUNT_TYPE_RESALE_PARTNER, IAccountState } from '../../store/account/types';
import CloudDown from '../../components/SvgIcons/CloudDown';
import { AppDispatch } from '../../configureStore';
import {Column} from "react-table-6";
import MetaButton from "../../components/MetaButton/MetaButton";
import SynturaBtn, { ButtonSize, ButtonType } from '../../components/SynturaBtn';

type TOwnProps = {
  user: IUserState;
  contact: IContactState;
  contacts: IContactsState;
  account: IAccountState;
  dispatch: AppDispatch;
};
const Contacts: FunctionComponent<TOwnProps> = (props) => {

  const { dispatch, account } = props;
  const {
    contacts, filtered, loading, searchString,
  } = props.contacts;
  const { contactTypes } = props.contact;

  useEffect(() => {

    if (account.accountTypeCode === ACCOUNT_TYPE_RESALE_PARTNER || contacts.length === 0) {

      refreshStore();

    }

  }, []);

  const onChangeSearch = (e) => {

    const searchString = e.target.value.toString().toLowerCase();
    dispatch(setContactsSearchString(searchString));

  };
  const refreshStore = () => {

    dispatch(resetContactsStore());
    dispatch(toggleContactsLoading());
    dispatch(getAccountContacts()).then(
      (result) => result && dispatch(populateContactTypes(contactTypes)),
    ).then(
      () => dispatch(toggleContactsLoading()),
    );

  };

  const exportAsCSV = () => {

    const toReduce = filtered.length ? filtered : contacts;
    return toReduce.reduce((result: Array<any>, contact) => {

      if (contact.contactTypeNames) {

        const reducedContact = {
          ...contact,
          contactTypes: contact.contactTypeNames.toString(),
          portalUser: contact.portalUser !== undefined,
          lastLogin: contact.portalUser ? contact.portalUser.lastLogin : '',
          portalRole: contact.portalUser ? contact.portalUser.role : '',
        };
        delete reducedContact.contactTypeNames;
        delete reducedContact.id;
        result.push(reducedContact);

      }
      return result;

    }, []);

  };
  const columns: Column[] = [
    {
      Header: 'Name',
      accessor: 'name',
      filterable: false,
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Last Login',
      accessor: 'portalUser',
      sortMethod: (a, b) => {

        a = a && a.lastLogin ? moment(a.lastLogin) : moment().add(10, 'years');
        b = b && b.lastLogin ? moment(b.lastLogin) : moment().add(10, 'years');

        return b.isBefore(a) ? -1 : 1;

      },
      Cell: (props) => (props.value && props.value.lastLogin ? moment(props.value.lastLogin).format('DD/MM/YYYY HH:mm:ss') : ''),

    },
    {
      Header: 'Status',
      accessor: 'isActive',
      maxWidth: 120,
      Cell: props => <MetaButton color={`${props.value ? 'success' : 'warning'}`} style={{height: '30px', minWidth: '86px'}}>{props.value ? 'Active' : 'Deactivated'}</MetaButton>
    },
    {
      Header: '',
      filterable: false,
      sortable: false,
      accessor: 'id',
      width: 50,
      Cell: (props) => (
        <Link
          style={{ textDecoration: 'none' }}
          className="grey-icon rounded"
          to={`/contacts/${props.value}`}
        >
          <Search title="details" />
        </Link>
      ),
    },
  ];
  return (
    <div className="animated fadeIn mb-3">
      <Section title="Contacts">
        <Row className="mb-4 d-flex justify-content-start">
          <Col className="d-flex flex-row" md={6}>
            <SearchInput value={searchString} onChange={onChangeSearch} />
            {checkPermission('contacts.write', props.user.permissions) && (
            <div style={{ width: '250px' }} className="ml-2 d-flex justify-content-space-between">
              {/* <ButtonLink url="/contacts/new" text="New Contact" color="primary" /> */}
              <Link to="/contacts/new">
                <SynturaBtn variant={ButtonType.PRIMARY} size={ButtonSize.SMALL}>
                  New Contact
                </SynturaBtn>
              </Link>
            </div>
            )}
          </Col>
          <Col md="6" className="d-flex justify-content-start justify-content-md-end mt-2 mt-md-0">
            <CSVLink disabled className={classnames('pt-1', 'px-1', 'btn', 'btn-warning', { disabled: loading })} color="warning" data={exportAsCSV()} filename="contacts.csv">
              <CloudDown cssClassTop="refresh-svg" title="Download as CSV" />
            </CSVLink>
            <Button className="pt-1 px-1 ml-2" color="primary" onClick={() => refreshStore()}>
              <Refresh title="Refresh" cssClassTop="refresh-svg" />
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 mr-0 ml-0">
          <Col className="p-4 bg-white rounded">
            <SimpleLoader loading={loading} text="Loading your contacts...">
              <ComplexTable
                columns={columns}
                data={searchString ? filtered : contacts}
                defaultSorted={[{ id: 'isActive', desc: true }, {id: 'name', desc: false}]}
              />
            </SimpleLoader>
          </Col>
        </Row>
      </Section>
    </div>
  );

};
function mapStateToProps({
  user, contacts, contact, account,
}) {

  return {
    contacts,
    contact,
    user,
    account,
  };

}
export default connect(mapStateToProps)(Contacts);
