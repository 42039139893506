import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Label,
  Row,
  Col,
  FormGroup,
  Input, Alert,
} from 'reactstrap'
import CardText from 'reactstrap/es/CardText';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { Link } from 'react-router-dom';
import { api_forgotPassword } from '../../utils/Auth/AuthService';
import { defaultErrorFeedback } from '../../actions/feedback';
import Section from '../../components/Section';
import Tick from '../../components/SvgIcons/Tick';
import SimpleLoader from '../../components/SimpleLoader';
import ApiErrorResolver from "../../helpers/ApiErrorResolver";
import SynturaLogo from '../../components/SVGS/logo';
import SynturaBtn, { ButtonType, ButtonSize } from '../../components/SynturaBtn';

const ForgotPassword = () => {

  const [username, setUsername] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleInput = (e) => {

    setError(false);
    setUsername(e.target.value);

  };

  const handleSubmit = () => {

    setApiErrors([])
    setSuccess(false);
    if (!username) {

      setError(true);
      return;

    }

    setLoading(true);
    api_forgotPassword(username).then((result) => {

      if (result.status === 200) {

        setSuccess(true);

      } else if (result.status === 422) {

        setApiErrors(ApiErrorResolver(result.data));

      } else {

        defaultErrorFeedback();

      }
      setLoading(false);

    });

  };

  if (success) {

    return (
      <div className="app flex-row align-items-center animated fadeIn syntura-dark-bg">
        <div className="container ">
          <div className="row justify-content-center">
            <Col md={8}>
              <Card className="mb-0 white-card-bg">
                <CardBody>
                  <Row>
                    <Col className="p-3 mb-4 d-flex justify-content-center">
                      <SynturaLogo textFill="#000" />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center align-items-center py-4">
                    <Col>
                      <div className="mb-4">
                        <div style={{ fontWeight: 600 }} className="h4 text-dark text-center">Your request has been submitted</div>
                      </div>

                      <div className="d-flex justify-content-center">
                        {' '}
                        <Tick cssClass="large-green-tick" />
                      </div>
                      <div className="d-flex justify-content-center">
                      <Link to="/login">
                        <SynturaBtn
                            size={ButtonSize.SMALL}
                            variant={ButtonType.PRIMARY}
                          >
                            Login
                        </SynturaBtn>
                      </Link>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </div>
    );

  }

  if (!success) {

    return (
      <div className="app flex-row align-items-center animated fadeIn syntura-dark-bg">
        <div className="container">
          <div className="row justify-content-center">
            <Col md={8}>
              <Card className="white-card-bg">
                <CardBody>
                  <Row>
                    <Col className="p-3 mb-4">
                      {/* <img className="img-fluid" alt="" src="../img/syntura-logo.svg" /> */}
                      <SynturaLogo textFill="#000" />
                    </Col>
                  </Row>
                  {apiErrors.length > 0 && (
                    <Row>
                      <Col className="p-3 mb-0">
                        <Alert color={"danger"}>{apiErrors.map((error, index) => {
                          return <p className={'mb-0'} key={'error' + index}>{error}</p>;
                        })}</Alert>
                      </Col>
                    </Row>
                  )}
                  <Section title="Password Reset">
                    <CardText>
                      Enter the username associated with your Syntura account. Password reset instructions will be sent via email.
                    </CardText>
                    <SimpleLoader loading={loading}>
                      <Row>
                        <Col>
                          <FormGroup className="row">
                            <Label className="col-sm-4 col-form-label" for="username">
                              Username
                            </Label>
                            <div className="col-sm-8">
                              <Input
                                id="username"
                                type="text"
                                className={error ? 'is-invalid' : ''}
                                onChange={handleInput}
                              />
                              {error && (
                                <div className="invalid-feedback">
                                  Invalid username
                                </div>
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <SynturaBtn
                            onClick={handleSubmit}
                            size={ButtonSize.SMALL}
                            variant={ButtonType.PRIMARY}
                          >
                            Submit
                          </SynturaBtn>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-end mt-1">
                          <Link to="/forgot-username" className="text-dark mt-2">Forgot username?</Link>
                        </Col>
                      </Row>
                    </SimpleLoader>
                  </Section>
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </div>
    );

  }

};

export default ForgotPassword;
