import {
  Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Section from '../../Section';

type TOwnProps = {
  isOpen: boolean;
  cancel: () => any;
  update: (event: React.ChangeEvent<HTMLInputElement>) => any;
};
const Tutorial: React.FC<TOwnProps> = (props) => {

  const { isOpen, cancel, update } = props;
  const handleCancel = () => {

    cancel();
  
  };
  const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {

    update(event);
  
  };
  // TODO: update to use isOpen={isOpen} once we have the rebranded video
  return (
    <Modal isOpen={false} toggle={handleCancel} backdrop="static" size="lg" color="primary">
      <ModalHeader toggle={handleCancel} className="border-bottom-0">
        <div style={{ fontWeight: 600 }} className="h4 mb-1 text-dark">Portal Overview</div>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="embed-responsive">
            <ReactPlayer
              controls
              url="https://vimeo.com/439375356/dfbb2e23ad"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="border-top-0">
        <FormGroup check>
          <Label check>
            <Input onChange={handleUpdate} type="checkbox" />
            {' '}
            Do not show this again
          </Label>
        </FormGroup>
      </ModalFooter>
    </Modal>
  );

};
export default Tutorial;
