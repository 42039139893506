/* eslint-disable */
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {composeWithDevTools} from 'redux-devtools-extension';
import middleware, {socketIOClient} from './middleware';
import rootReducer from './reducers';
import {createStateSyncMiddleware, initStateWithPrevTab} from "redux-state-sync";
import {authenticateSocket, joinRooms} from "./store/socketio/actions";
import {addJTISubs} from "./store/JTI/actions";
import {SOCKET_CONNECT, SOCKET_DISCONNECT} from "./store/socketio/types";
import { store } from './index'

export const persistConfig = {
  whitelist: ['helpers', 'account',  'user', 'domainCart', 'domainCheckout', 'system'],
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {

  let store;

  // const store = createStore(rootReducer, {}, applyMiddleware(...middlewares));
  // init state with other tabs
  store = createStore(persistedReducer, composeWithDevTools(middleware));

  initStateWithPrevTab(store);
  const persistor = persistStore(store);
  // @ts-ignore
  if (window.Cypress) {

    // we are running in Cypress
    // expose the redux store
    // @ts-ignore
    window.reduxStore = store;
    // window.cypressStart();
      /* eslint-disable */
      if (window.hasOwnProperty('cypressStart')) {
        // @ts-ignore
        window.cypressStart();
      }
      /* eslint-enable */

  }

  socketIOClient.on('disconnect', (reason) => {

    store.dispatch({ type: SOCKET_DISCONNECT });
    if (reason === 'io server disconnect') {

      // socket.connect();

    } else {

      console.error(`Connection to socketio lost: ${reason}`);
      console.log(socketIOClient);

    }

  });

  socketIOClient.on('reconnect', (attempt) => {

    store.dispatch({ type: SOCKET_CONNECT });

    console.log(`Reconnected to socketio on attempt: ${attempt}`);

    if (localStorage.getItem('hso-api-token')) {

      store.dispatch(authenticateSocket({ jwt: localStorage.getItem('hso-api-token') }));
      const st = store.getState();
      store.dispatch(joinRooms({ rooms: st.authenticationState.account.permissions }));
      if (Object.keys(st.JTI.subs).length > 0) {

        store.dispatch(addJTISubs(st.JTI.subs));

      }

    }

  });
  return { store, persistor };

};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
