/* eslint-disable */
import React from 'react';
import {PropTypes} from 'prop-types';
import {Card, CardSubtitle, CardHeader, CardBody, Button, FormGroup, Label, Alert, CardText, Row, Col} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {DateTimePicker} from 'react-widgets';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import { connect } from 'react-redux';
import {createNewCase} from '../../store/case/actions';
import Section from '../../components/Section';

momentLocalizer(Moment);

class ADSLFault extends React.Component{
  static contextTypes = {
    router: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      staticContext: PropTypes.object
    }).isRequired
  };

  constructor(props) {

    super(props);
    this.state = {
      _faultType: null,
      formData: {},
      formDataOptional: {},
      engineerDate: Moment().add(1, 'days').toDate(),
      faultStartDate: new Date(),
      processing: false,
      error: false
    }
    ;
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleFaultType = this.handleFaultType.bind(this);
    this.handleOptionalInput = this.handleOptionalInput.bind(this);

  }

  handleInput(event, value){

    event.persist();
    this.setState((prevState, props) => {

      return {
        formData: Object.assign(prevState.formData, {[event.target.id] : {
          question: event.target.parentNode.previousSibling.textContent,
          value
        }})
      };

    });

  }

  handleOptionalInput(event, value){

    event.persist();
    this.setState((prevState, props) => {

      return {
        formDataOptional: Object.assign(prevState.formDataOptional, {[event.target.id] : {
          question: event.target.parentNode.previousSibling.textContent,
          value
        }})
      };

    });

  }

  handleFaultType(event, value){

    return this.setState({
      _faultType: value,
      formDataOptional: {_faultType : {
        question: event.target.parentNode.previousSibling.textContent,
        value
      }}
    });

  }

  handleDate(date, id, field){

    this.setState((prevState, props) => {

      return {
        formData: Object.assign(prevState.formData, {
          [id] : {
            question: field,
            value: date
          }
        })
      };

    });

  }

  handleSubmit(){

    if (this.props.partnerCustomer === ''  &&  this.props.isResalePartner) {

      this.props.setCustomerSelectError();
      return false;

    }
    this.setState({processing: true});
    const title = this.state.formData.title.value;
    let theHTML = '<h2>ADSL Fault</h2><br>';
    theHTML += `<h6>Title</h6><p>${  title  }</p>`;
    delete this.state.formData.title;
    for (const prop in this.state.formDataOptional) {

      if(!this.state.formDataOptional.hasOwnProperty(prop)) continue;
      theHTML += `<h6>${ this.state.formDataOptional[prop].question  }</h6><p>${  this.state.formDataOptional[prop].value  }</p>`;

    }
    if(!this.state.formData.faultStartDate){

      theHTML += `<h6>When did the fault start happening?</h6><p>${  this.state.faultStartDate  }</p>`;

    }
    if(!this.state.formData.engineerDate){

      theHTML += `<h6>Preferred Date for Engineer's visit</h6><p>${  this.state.engineerDate  }</p>`;

    }

    for (const prop in this.state.formData) {

      if(!this.state.formData.hasOwnProperty(prop)) continue;
      theHTML += `<h6>${ this.state.formData[prop].question  }</h6><p>${  this.state.formData[prop].value  }</p>`;

    }


    this.props.dispatch(createNewCase({
      title,
      priority: this.state.formData.priority.value,
      category: 'service_affecting',
      formData: theHTML,
      partnerCustomerId: this.props.partnerCustomer
    })).then((result) => {

      if(result.caseId){

        this.props.navigate('/cases');

      }
      this.setState({error:true});
      window.scrollTo(0, 0);

    });

  }

  render() {

    let faultTypeContent;

    if(this.state._faultType === 'no connection'){

      faultTypeContent = (
                <Alert color="warning">
                    Check for BT Outages <a href="http://btbusiness.custhelp.com/app/service_status" target="_blank" rel="noopener noreferrer" >here</a>.
                </Alert>
      );

    } else if(this.state._faultType === 'intermittent'){

      faultTypeContent = (
                <FormGroup>
                    <Label for="typeIntermittentInfo">How often does the connection drop?</Label>
                    <AvField type="text" name="typeIntermittentInfo" id="typeIntermittentInfo" onChange={this.handleOptionalInput}/>
                </FormGroup>
      );

    } else if(this.state._faultType === 'slow connectivity'){

      faultTypeContent = (
                <div>
                    <Alert color="warning">
                        Please provide results below from <a href="https://www.speedtest.net" target="_blank" rel="noopener noreferrer" >https://www.speedtest.net/</a> by selecting Syntura Group Limited.
                    </Alert>
                    <FormGroup>
                        <Label for="connectivityDown">Max Download</Label>
                        <AvField type="text" name="connectivityDown" id="connectivityDown" onChange={this.handleOptionalInput}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="connectivityUp">Max Upload</Label>
                        <AvField type="text" name="connectivityUp" id="connectivityUp" onChange={this.handleOptionalInput}/>
                    </FormGroup>
                </div>
      );

    } else if(this.state._faultType === 'other'){

      faultTypeContent = (
                <FormGroup>
                    <Label for="typeOtherDetails">Please provide details</Label>
                    <AvField type="textarea" name="typeOtherDetails" id="typeOtherDetails" onChange={this.handleOptionalInput}/>
                </FormGroup>
      );

    }
    return (
            <div>
                {this.state.error &&
                <Alert color="danger">
                    <strong>Oh snap!</strong> Something went seriously wrong with this one!
                </Alert>
                }
                <Section title="ADSL Fault">
                    <Row className="mx-0">
                        <Col className="p-4 bg-white rounded">
                        <AvForm onValidSubmit={this.handleSubmit} onInvalidSubmit={this.props.onInvalidSubmit}>
                            <FormGroup>
                                <Label for="title">Title</Label>
                                <AvField type="text" name="title" id="title" placeholder="Title of the case" required onChange={this.handleInput} validate={{maxLength: {value: 200}}}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="priority">Priority</Label>
                                <AvField type="select" name="priority" id="priority" required onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="pstn">PSTN Number</Label>
                                <AvField type="text" name="pstn" id="pstn" placeholder="ADSL line telephone number" required onChange={this.handleInput}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="accountNumber">Account Number</Label>
                                <AvField type="text" name="accountNumber" id="accountNumber" placeholder="Account number can be found on white sticker on router" onChange={this.handleInput}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="contact">Contact</Label>
                                <AvField type="text" name="contact" id="contact" placeholder="Contact Telephone/Mobile number" required onChange={this.handleInput}/>
                            </FormGroup>
                            <FormGroup className="clearfix">
                                <div>
                                    <Label className="clearfix">Preferred Date for Engineer's visit</Label>
                                </div>
                                <DateTimePicker defaultValue={this.state.engineerDate} format="DD-MM-YYYY" time={false} min={new Date()} onChange={(theDate, dateFormatted) => {

                                  this.handleDate(dateFormatted, 'engineerDate', "Preferred Date for Engineer's visit");

                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="engineerTimeSlot">Preferred Time Slot</Label>
                                <AvField type="select" name="engineerTimeSlot" id="engineerTimeSlot" required onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="am">8AM - 1PM</option>
                                    <option value="pm">1PM - 6PM</option>
                                </AvField>
                            </FormGroup>
                            <CardSubtitle style={{marginTop: '10px', marginBottom: '10px'}}>Fault Description</CardSubtitle>
                            <FormGroup>
                                <Label for="faultType">What is the Fault?</Label>
                                <AvField type="select" name="faultType" id="faultType" onChange={this.handleFaultType} required>
                                    <option value="">-- Select</option>
                                    <option value="no connection">No Connection</option>
                                    <option value="intermittent">Intermittent</option>
                                    <option value="slow connectivity">Slow Connectivity</option>
                                    <option value="other">Other</option>
                                </AvField>
                            </FormGroup>
                            {this.state._faultType && faultTypeContent}

                            <FormGroup className="clearfix">
                                <div>
                                    <Label className="clearfix">When did the fault start happening?</Label>
                                </div>
                                <DateTimePicker step="60" defaultValue={this.state.faultStartDate} format="DD-MM-YYYY hA" max={new Date()} onChange={(theDate, dateFormatted) => {

                                  this.handleDate(dateFormatted, 'faultStartDate', 'When did the fault start happening?');

                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="everWorked">Has the DSL service ever worked?</Label>
                                <AvField type="select" name="everWorked" id="everWorked" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </AvField>
                            </FormGroup>
                            <CardText style={{marginTop: '10px', marginBottom: '10px'}}>What is the status of lights on the router?</CardText>
                            <FormGroup>
                                <Label for="routerLightPower">Power</Label>
                                <AvField type="select" name="routerLightPower" id="routerLightPower" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="red">Red</option>
                                    <option value="green (solid)">Green (solid)</option>
                                    <option value="green (flickering)">Green (flickering)</option>
                                    <option value="unlit">Unlit</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="routerLightDSL">DSL</Label>
                                <AvField type="select" name="routerLightDSL" id="routerLightDSL" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="red">Red</option>
                                    <option value="green (solid)">Green (solid)</option>
                                    <option value="green (flickering)">Green (flickering)</option>
                                    <option value="unlit">Unlit</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="routerLightInternet">Internet</Label>
                                <AvField type="select" name="routerLightInternet" id="routerLightInternet" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="red">Red</option>
                                    <option value="green (solid)">Green (solid)</option>
                                    <option value="green (flickering)">Green (flickering)</option>
                                    <option value="unlit">Unlit</option>
                                </AvField>
                            </FormGroup>
                            <CardSubtitle style={{marginTop: '10px', marginBottom: '10px'}}>Diagnostic Tests</CardSubtitle>
                            <FormGroup>
                                <Label for="changes">Have any changes been made to the telephone line by either the end user or BT <small>(including structural or service related)</small>?</Label>
                                <AvField type="select" name="changes" id="changes" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="resync">Please confirm router has been switched off for 30 minutes and re-tested? <small>This forces a re-sync at the BT exchange</small></Label>
                                <AvField type="select" name="resync" id="resync" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="dialtone">Please plug a handset into the line, is a dial tone heard?</Label>
                                <AvField type="select" name="dialtone" id="dialtone" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="filter">Does the microfilter look damaged or old?</Label>
                                <AvField type="select" name="filter" id="filter" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="filter_reseat">Can it be re-seated?</Label>
                                <AvField type="select" name="filter_reseat" id="filter_reseat" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="master_socket">Is the router connected to the master socket or an extension? <small>(please try connecting it to the master socket & retest)</small></Label>
                                <AvField type="select" name="master_socket" id="master_socket" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="master">Master</option>
                                    <option value="extension">Extension</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="socket_filter">Do all sockets have a microfilter? <small> (If possible please test with a different microfilter)</small></Label>
                                <AvField type="select" name="socket_filter" id="socket_filter" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="disconnect">Disconnect everything else from the telephone line besides the router. Does the problem still occur?</Label>
                                <AvField type="select" name="disconnect" id="disconnect" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="adapter">Please verify that the power adapter used by the router is the one supplied by Syntura with the router?<small> (Using any other power adapter can damage the router and cause connectivity issues)</small> </Label>
                                <AvField type="select" name="adapter" id="adapter" onChange={this.handleInput}>
                                    <option value="">-- Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </AvField>
                            </FormGroup>
                            <FormGroup>
                                <Label for="furtherInformation">Further Information</Label>
                                <AvField type="textarea" rows="6" id="furtherInformation" name="furtherInformation" onChange={this.handleInput}/>
                            </FormGroup>
                            <div className="clearfix" style={{marginTop: `${10}px`, marginBottom: `${20}px`}}>
                                {/* <Button type="submit" color="primary" className="float-right" style={{minWidth: 113+'px', minHeight: 35+'px'}} disabled={this.state.processing}>{this.state.processing ? <div style={{marginTop: 2+'px'}}>
                                    <Loader color="#ffffff" size="8px" margin="0px"/></div> : "Create Case"}
                                    </Button> */}
                                <LaddaButton
                                    style={{minWidth: `${113}px`, minHeight: `${35}px`}}
                                    className="btn btn-primary btn-ladda px-4 float-right"
                                    disabled={this.state.processing}
                                    loading={this.state.processing}
                                    data-color="blue"
                                    data-style={ZOOM_OUT}
                                >
                                    Create Case
                                </LaddaButton>
                            </div>
                        </AvForm>
                        </Col>
                    </Row>
                </Section>
            </div>

    );

  }
}

export default connect()(ADSLFault);
