import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useParams } from 'react-router-dom';
import { toggleSignatureLoading, getSignature } from '../../store/signature/actions';
import HelloSignEmbedded from '../../components/HelloSignEmbedded';

import { ISignatureState } from '../../store/signature/types';
import SimpleLoader from '../../components/SimpleLoader';
import { GenericNotFound } from '../../components/Errors/Errors';
import { AppDispatch } from '../../configureStore';

type TParams = { signatureid: string };

type SignatureProps = {
  dispatch: AppDispatch;
  theSignature: ISignatureState;
};

type Props = SignatureProps;
const Signature: React.FC<Props> = (props) => {

  const [status, setStatus] = useState('processing');

  const params = useParams<TParams>();

  useEffect(() => {

    props.dispatch(toggleSignatureLoading());
    props.dispatch(getSignature(params.signatureid)).then(() => props.dispatch(toggleSignatureLoading()));

  }, []);

  if (props.theSignature.loading && !props.theSignature.data.signUrl) {

    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div>
                <SimpleLoader loading={props.theSignature.loading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
  if (!props.theSignature.loading && !props.theSignature.data.signUrl) {

    return <GenericNotFound />;

  }
  return (
    <div className="app flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="clearfix">
              {status === 'cancelled'
                ? (
                  <div>
                    <h1 className="float-left display-3 mr-4"><i className="fa fa-close text-danger" /></h1>
                    <h4 className="pt-3">Request Cancelled</h4>
                    <p className="text-muted">You may now close this page.</p>
                  </div>
                )
                : status === 'processing' ? ''
                  : status === 'reassigned'
                    ? (
                      <div>
                        <h1 className="float-left display-3 mr-4"><i className="fa fa-check text-success" /></h1>
                        <h4 className="pt-3">Request Re-assigned</h4>
                        <p className="text-muted">You may now close this page.</p>
                      </div>
                    )
                    : status === 'signed'
                      ? (
                        <div>
                          <h1 className="float-left display-3 mr-4"><i className="fa fa-check text-success" /></h1>
                          <h4 className="pt-3">Request Signed</h4>
                          <p className="text-muted">You may now close this page.</p>
                        </div>
                      )
                      : status === 'declined'
                        ? (
                          <div>
                            <h1 className="float-left display-3 mr-4"><i className="fa fa-check text-danger" /></h1>
                            <h4 className="pt-3">Request Declined</h4>
                            <p className="text-muted">You may now close this page.</p>
                          </div>
                        )
                        : status}
            </div>
          </div>
        </div>
      </div>

      <HelloSignEmbedded
        clientId={props.theSignature.data.clientId}
        url={props.theSignature.data.signUrl}
        messageListener={(newStatus) => setStatus(newStatus)}
      />
    </div>
  );

};

function mapStateToProps({ signature: theSignature }) {

  return {
    theSignature,
  };

}

export default connect(mapStateToProps)(Signature);
