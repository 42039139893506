import React from 'react';
import {
  Alert, Card, CardBody, Col, FormGroup, Label, Row,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import Section from '../Section';
import Tick from '../SvgIcons/Tick';
import { PASSWORD_POLICY_REGEX } from '../../helpers/password';
import { ButtonSize } from '../SynturaBtn';
import { ButtonType } from '../SynturaBtn';
import SynturaBtn from '../SynturaBtn';
import SynturaLogo from '../SVGS/logo';

type TOwnProps = {
  submit: () => any;
  headerText: string;
  handleInput: (event: React.ChangeEvent<HTMLInputElement>) => any;
  errors: Record<string, any>;
  username: string;
  password: string;
  passwordConfirm: string;
  processing: boolean;
  completed: boolean;
  tokenExpired: boolean;
};

const Registration: React.FC<TOwnProps> = (props) => {

  const {
    submit, headerText, handleInput, username, password, passwordConfirm, processing, completed, tokenExpired,
  } = props;

  return (
    <Card className='white-card-bg'>
      <CardBody>
        <Row>
          <Col className="p-3 mb-4">
            <SynturaLogo textFill="#000" />
          </Col>
        </Row>
        {tokenExpired
          && (
            <Row>
              <Col>
                <Alert color="danger">Your registration token has expired. Please note tokens are only valid for a limited amount of time.</Alert>
              </Col>
            </Row>
          )}
        {
          !isEmpty(props.errors)

          && (
            <Row>
              <Col>
                <Alert color="danger">
                  {Object.values(props.errors).map((error, index) => <p key={`error-${index}`} className="mb-0">{error}</p>)}
                </Alert>
              </Col>
            </Row>
          )
        }
        {completed
          ? (
            <Col>
              <div className="mb-4">
                <div style={{ fontWeight: 600 }} className="h4 text-dark text-center">Your registration is now complete.</div>
              </div>
              <div className="d-flex justify-content-center">
                {' '}
                <Tick cssClass="large-green-tick" />
              </div>
              <div className="mt-4">
                <Link to="/login">
                  <SynturaBtn
                    size={ButtonSize.SMALL}
                    variant={ButtonType.PRIMARY}
                    style={{ float: 'right' }}
                  >
                    Login
                  </SynturaBtn>
                </Link>
              </div>
            </Col>
          )
          : (
            <Section title={headerText}>
              <Row>
                <Col>
                  <AvForm
                    onValidSubmit={submit}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onInvalidSubmit={() => { }}
                  >
                    <Row>
                      <Col sm={12}>
                        <div>
                          <FormGroup className="row">
                            <Label
                              className="col-sm-4 col-form-label"
                              for="username"
                            >
                              Username
                            </Label>
                            <div className="col-sm-8">
                              <AvField
                                required
                                onChange={handleInput}
                                name="username"
                                id="username"
                                className="form-control"
                                type="text"
                                value={username}
                                validate={{
                                  pattern: {
                                    value: /^[a-z0-9]{8,50}$/i,
                                  },
                                }}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="col-sm-4 col-form-label"
                              for="password"
                            >
                              Password
                            </Label>
                            <div className="col-sm-8">
                              <AvField
                                required
                                onChange={handleInput}
                                name="password"
                                id="password"
                                className="form-control"
                                type="password"
                                validate={{
                                  pattern: {
                                    value: PASSWORD_POLICY_REGEX,
                                  },
                                }}
                                value={password}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="col-sm-4 col-form-label"
                              for="passwordConfirm"
                            >
                              Confirm Password
                            </Label>
                            <div className="col-sm-8">
                              <AvField
                                required
                                onChange={handleInput}
                                name="passwordConfirm"
                                id="passwordConfirm"
                                className="form-control"
                                type="password"
                                value={passwordConfirm}
                                validate={{ match: { value: 'password' } }}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup className="float-right">
                            <SynturaBtn
                              size={ButtonSize.SMALL}
                              variant={ButtonType.PRIMARY}
                              style={{ float: 'right' }}
                            >
                              Submit
                            </SynturaBtn>
                          </FormGroup>
                        </div>
                      </Col>

                    </Row>
                    <Row>
                      <Col>
                        <small>
                          Username must be between 14 to 50 characters long, contain only letters and numbers.
                        </small>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <small>
                          Passwords must be between 14 to 64 characters long, contain
                          at least one (1) character from each of the following
                          categories:
                        </small>
                        <ul className="small pl-3">
                          <li>Uppercase letter (A-Z)</li>
                          <li>Lowercase letter (a-z)</li>
                          <li>Digit (0-9)</li>
                          <li>Special Characters (!@#$&)</li>
                        </ul>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Row>
            </Section>
          )}

      </CardBody>
    </Card>
  );

};

export default Registration;
