import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Col, Row, CardBody, Card, Collapse, FormGroup,
} from 'reactstrap';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import TextInput from '../../components/Inputs/TextInput';
import ContactCard from '../../components/Widgets/ContactCard';
import {
  assignActivityIsOpenFields,
  getCase,
  toggleActivity,
  caseToggleModal,
  setCaseResolveReasonDropdown,
  toggleCaseReasonDropdownOpen,
  updateCaseResolved,
  toggleCaseModalLoading,
  toggleCaseEscalationModalLoading,
  toggleCaseLoading,
  setCaseReplyFormOpen,
  setCaseReplyValue,
  setDescError,
  setReplyProcessing,
  caseUpdateResolveReason,
  caseUpdateEscalationNote,
  caseResolveSetErrorText,
  caseEscalationToggleModal,
  caseEscalationSetErrorText,
  updateCaseEscalated,
  getCaseActivity, toggleActivityContentLoading,
} from '../../store/case/actions';
import { updateCase } from '../../utils/Cases/Cases';
import { IActivity, ICaseState } from '../../store/case/types';

import Section from '../../components/Section';
import { Gravatar } from '../../components/Gravatar/Gravatar';
import TextEditor from '../../components/TextEditor/TextEditor';
import EditIconButton from '../../components/Buttons/EditIconButton';
import SimpleLoader from '../../components/SimpleLoader';
import ResolveCase from '../../components/Modals/ResolveCase';
import EscalateCase from '../../components/Modals/EscalateCase';
import { IUserState } from '../../store/user/types';
import { checkPermission } from '../../utils/Auth/AuthService';
import { successFeedback } from '../../actions/feedback';
import { IAccountState } from '../../store/account/types';
import { resolveCaseInCases } from '../../store/cases/actions';
import { AppDispatch } from '../../configureStore';

type TParams = { id: string };
type CaseProps = {
  user: IUserState;
  dispatch: AppDispatch;
  theCase: ICaseState;
  account: IAccountState;
};
type Props = CaseProps;
const getEmailType = (type, email) => {

  switch (type) {
    case 'Email':
      if (email.match(/(syntura.io|hso.uk.com|goscomb.net)/)) {

        return { cssClass: 'accord--email-inbound', label: 'Inbound email' };
      
      }
      return { cssClass: 'accord--email-outbound', label: 'Outbound email' };
    case 'Phone Call':
      return { cssClass: 'accord--phone', label: 'Phone' };
    case 'Case Resolution':
      return { cssClass: 'accord--resolved', label: 'Resolved' };
    default:
      return { cssClass: '', label: '' };
  }

};
const Case: React.FC<Props> = (props) => {

  const { dispatch, account } = props;
  const {
    data, loading, showReplyForm, replyValue, replyProcessing, descError, reasonDropdownOpen, caseModalLoading, escalationcaseModalLoading,
  } = props.theCase;
  const {
    escalationNote, escalationModalOpen, resolveReason, modalOpen, caseId, resolveModalErrorText, escalateModalErrorText, resolveReasonDropdown,
  } = data;
  const { state } = data; // 'Resolved'
  const params = useParams();
  const getC = (id) => {

    dispatch(toggleCaseLoading());
    dispatch(getCase(id)).then(() => {

      dispatch(toggleCaseLoading());
      dispatch(assignActivityIsOpenFields());
    
    });
  
  };

  const escalateC = () => {

    if (!escalationNote) {

      dispatch(caseEscalationSetErrorText('Please describe why the case is being escalated'));
    
    } else if (escalationNote.length > 250) {

      dispatch(caseEscalationSetErrorText(`Maximum length is 250 characters you have ${escalationNote.length} characters.`));
    
    } else {

      dispatch(toggleCaseEscalationModalLoading());
      dispatch(updateCaseEscalated(caseId, escalationNote)).then((escalated) => {

        if (escalated === true) {

          dispatch(caseEscalationToggleModal());
          dispatch(successFeedback(`${data.caseId} has been escalated!`));
          getC(params.id);
        
        }
        dispatch(toggleCaseEscalationModalLoading());
      
      });
    
    }
  
  };
  const resolveC = () => {

    if (!resolveReason) {

      dispatch(caseResolveSetErrorText('Please describe why the case is resolved'));
    
    } else if (resolveReasonDropdown === 0 || resolveReasonDropdown === undefined) {

      dispatch(caseResolveSetErrorText('Please select an case category'));
    
    } else if (resolveReason.length > 250) {

      dispatch(caseResolveSetErrorText(`Maximum length is 250 characters you have ${resolveReason.length} characters.`));
    
    } else {

      dispatch(toggleCaseModalLoading());
      dispatch(updateCaseResolved(caseId, resolveReason, resolveReasonDropdown)).then((resolved) => {

        if (resolved === true) {

          dispatch(caseToggleModal());
          dispatch(successFeedback(`${data.caseId} has been resolved!`));
          dispatch(resolveCaseInCases(data.caseId));
        
        }
      
      }).then(() => {

        dispatch(toggleCaseModalLoading());
      
      });
    
    }
  
  };
  useEffect(() => {

    getC(params.id);
  
  }, []);

  const caseResolved = state === 'Resolved' || state === 'Canceled';
  const toggleReply = () => {

    dispatch(setCaseReplyFormOpen());
  
  };

  const toggleActivityState = (id) => {

    const activity = data.activities.find((act) => id === act.id);
    if (activity && !activity.isOpen && !activity.content && activity.type !== 'Case Resolution') {

      dispatch(toggleActivityContentLoading(id));
      dispatch(getCaseActivity(data.caseId, activity.id)).then(() => dispatch(toggleActivityContentLoading(id)));
    
    }
    dispatch(toggleActivity(id));
  
  };
  const datacallback = (fromChild) => {

    dispatch(setCaseReplyValue(fromChild));
  
  };
  const updateC = () => {

    if (replyValue === '' || replyValue === null || replyValue.replace(/&nbsp;/g, '').replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '').trim() === '') {

      dispatch(setDescError(true));
      return false;
    
    }
    dispatch(setReplyProcessing(true));
    updateCase(params.id, replyValue).then((result) => {

      toggleReply();
      getC(params.id);
      dispatch(setReplyProcessing(false));
    
    });
  
  };
  const createMarkup = (markup) => ({ __html: markup });
  const onResolveChange = (event) => {

    dispatch(caseUpdateResolveReason(event.target.value));
  
  };
  const onEscalateChange = (event) => {

    dispatch(caseUpdateEscalationNote(event.target.value));
  
  };
  const onResolutionTypeChange = (payload) => {

    dispatch(setCaseResolveReasonDropdown(payload));
  
  };
  const toggleModal = () => {

    dispatch(caseToggleModal());
  
  };
  const caseEscalationModal = () => {

    dispatch(caseEscalationToggleModal());
  
  };
  const toggleCaseReasonDropdownOpenHandler = () => {

    dispatch(toggleCaseReasonDropdownOpen());
  
  };
  return (
    <div className="animated fadeIn mb-5" style={{ minHeight: '500px' }}>
      <SimpleLoader loading={loading} text="Loading your case...">
        {!loading
                && (
                <>
                  <div className="animated fadeIn mb-3">
                    <Section title={`${data.caseId} - ${data.title ? `${data.title.substring(0, 100)}` : ''}`}>
                      <Row className="bg-white pt-4 pl-4 m-0" style={{ borderRadius: '10px' }}>
                        <Col sm="4">
                          <TextInput id="Title" label="Title" value={data.title} truncate readOnly />
                          <TextInput
                            id="Number"
                            label="Case Number"
                            value={data.caseId}
                            readOnly
                          />
                          <TextInput
                            id="Created"
                            label="Created"
                            value={moment(data.createdOn).format('DD/MM/YYYY')}
                            readOnly
                          />
                          <TextInput
                            id="Modified"
                            label="Modified"
                            value={moment(data.modifiedOn).format('DD/MM/YYYY')}
                            readOnly
                          />
                          <TextInput
                            id="Origin"
                            label="Origin"
                            truncate
                            value={data.origin}
                            readOnly
                          />
                        </Col>
                        <Col sm="4">
                          <TextInput
                            id="Priority"
                            label="Priority"
                            truncate
                            value={data.priority}
                            readOnly
                          />
                          <TextInput id="State" label="State" truncate value={data.state} readOnly />
                          <TextInput id="Status" label="Status" truncate value={data.status} readOnly />
                          <TextInput
                            id="Opened By"
                            label="Opened By"
                            truncate
                            value={data.openedBy}
                            readOnly
                          />
                        </Col>
                        <Col sm="4">
                          <h5><strong>Your Case Manager</strong></h5>
                          <ContactCard
                            dropShadow={false}
                            firstName={data.assignedTo || 'NOC'}
                            lastName=""
                            email="support@syntura.io"
                            phone={account.hsoSupport}
                            jobTitle="Case  Manager"
                          />
                        </Col>
                      </Row>
                    </Section>
                  </div>
                  <Section title="Activity">
                    {!caseResolved && checkPermission('cases.write', props.user.permissions) && (
                    <Row>
                      <Col>
                        <LaddaButton
                          className="btn btn-primary btn-ladda px-4 mb-4"
                          data-color="blue"
                          data-style={ZOOM_OUT}
                          onClick={toggleModal}
                        >
                          Resolve case
                        </LaddaButton>
                        <LaddaButton
                          className="btn btn-primary btn-ladda px-4 mb-4 ml-2"
                          data-color="blue"
                          data-style={ZOOM_OUT}
                          onClick={caseEscalationModal}
                        >
                          Escalate
                        </LaddaButton>
                      </Col>
                    </Row>
                    )}
                    <div className="card p-5 accord" style={{ borderRadius: '10px' }}>
                      <div className="row d-flex justify-content-end mb-4">
                        <Col className=" d-flex justify-content-end ">
                          {!caseResolved && checkPermission('cases.write', props.user.permissions) && (
                          <EditIconButton onClick={toggleReply} />
                          )}
                        </Col>
                      </div>
                      <Collapse isOpen={showReplyForm}>
                        <Row>
                          <Col>
                            <FormGroup color="null">
                              <TextEditor callback={datacallback} color={'descError ? "danger" : null'} />
                              {descError
                                            && <p className="mt-1 text-danger">Reply text is a required field</p>}
                              <div
                                className="clearfix"
                                style={{ marginTop: `${10}px`, marginBottom: `${20}px` }}
                              >
                                <LaddaButton
                                  style={{ minWidth: `${113}px`, minHeight: `${35}px` }}
                                  className="btn btn-primary btn-ladda px-4 float-right"
                                  disabled={replyProcessing}
                                  loading={replyProcessing}
                                  data-color="blue"
                                  data-style={ZOOM_OUT}
                                  onClick={() => updateC()}
                                >
                                  Update Case
                                </LaddaButton>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Collapse>
                      {data.activities && data.activities.map((activity) => (
                        <Fragment key={activity.id}>
                          <Row
                            onClick={() => toggleActivityState(activity.id)}
                            className={` accord__bar p-3 mb-2 ${getEmailType(activity.type, activity.emailAddresses.from).cssClass}`}
                          >
                            <Col className="d-flex align-items-center" md="3">
                              <span
                                className="text-uppercase"
                              >
                                {' '}
                                {getEmailType(activity.type, activity.emailAddresses.from).label}
                                {' '}

                              </span>
                            </Col>
                            <Col className="d-flex align-items-center text-dark" md="3">
                              <span>{moment(activity.modifiedOn).format('DD/MM/YYYY')}</span>
                            </Col>
                            <Col className="d-flex align-items-center text-dark " md="4">
                              <span className="truncate">{activity.subject}</span>
                            </Col>
                            <Col className="d-flex align-items-center text-light" md="2">
                              {activity.isOpen && (<i className="fa fa-minus text-light ml-auto" />)}
                              {!activity.isOpen && (<i className="fa fa-plus text-light  ml-auto" />)}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <Collapse isOpen={activity.isOpen}>
                                <Card style={{ boxShadow: 'none', border: 'none' }}>
                                  <CardBody>
                                    <SimpleLoader loading={activity.contentLoading}>
                                      <Gravatar
                                        email={activity.emailAddresses.from}
                                        size={45}
                                        className="img-avatar"
                                      />
                                      {' '}
                                      {activity.createdBy}
                                      {activity.content && (
                                      <div
                                        dangerouslySetInnerHTML={createMarkup(activity.content.toString())}
                                      />
                                      )}
                                    </SimpleLoader>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            </Col>
                          </Row>
                        </Fragment>
                      ))}
                    </div>
                  </Section>
                </>
                )}
      </SimpleLoader>
      <ResolveCase
        resolveReasonDropdown={resolveReasonDropdown}
        reasonDropdownOpen={reasonDropdownOpen}
        toggleReasonDropdownOpen={toggleCaseReasonDropdownOpenHandler}
        caseModalLoading={caseModalLoading}
        onResolutionTypeChange={onResolutionTypeChange}
        resolveReasonValue={resolveReason}
        changeHandler={(event) => onResolveChange(event)}
        error={resolveModalErrorText}
        isOpen={modalOpen}
        cancel={() => dispatch(caseToggleModal())}
        proceed={resolveC}
      />
      <EscalateCase
        isOpen={escalationModalOpen}
        cancel={() => dispatch(caseEscalationToggleModal())}
        escalationNote={escalationNote}
        changeHandler={(event) => onEscalateChange(event)}
        error={escalateModalErrorText}
        escalationcaseModalLoading={escalationcaseModalLoading}
        proceed={escalateC}
      />
    </div>
  );

};
function mapStateToProps({ user, case: theCase, account }) {

  return {
    user, theCase, account,
  };

}
export default connect(mapStateToProps)(Case);
