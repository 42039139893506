import React, {
  Component, FunctionComponent, JSX, useEffect, useState,
} from 'react';
import { Row, Col, Alert } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import { useParams } from 'react-router-dom';
import { registerAccount } from '../../utils/Account/Account';
import { GenericNotFound } from '../../components/Errors/Errors';
import Registration from '../../components/Registration';

const AccountRegistration: FunctionComponent = (): JSX.Element => {

  const params = useParams();

  const [token, setToken] = useState<string | null>(params.token || null);
  const [tokenExpired, setTokenExpired] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [registrationError, setRegistrationError] = useState<boolean>(false);

  const [errors, setErrors] = useState({});
    type TData = {
      username: string;
      password: string;
      passwordConfirm: string;
    };
    const [data, setData] = useState<TData>({
      username: '',
      password: '',
      passwordConfirm: '',
    });

    useEffect(() => {

      if (params.token && /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(params.token)) {

        setToken(params.token);
      
      }
    
    }, [params.token]);

    const handleInput = (event) => {

      event.persist();
      const { id } = event.target;
      setData((prevState) => ({
        ...prevState,
        [id]: event.target.value,
      }));
    
    };

    const submit = () => {

      setProcessing(true);
      setRegistrationError(false);

      registerAccount(token, data.username, data.password)
        .then((result) => {

          if (result.status === 400) {

            if (result.data.error === 'expired token') {

              setTokenExpired(true);
            
            } else {

              setRegistrationError(true);
            
            }
          
          } else if (result.status === 422) {

            setErrors(result.data);
          
          } else if (result.status === 200) {

            setCompleted(true);
          
          }
          setProcessing(false);
        
        });
    
    };

    if (!token) {

      return <GenericNotFound />;
    
    }

    return (
      <div className="app flex-row align-items-center animated fadeIn syntura-dark-bg">
        <div className="container">
          <div className="row justify-content-center">
            <Col md={8}>
              <Registration
                errors={errors}
                submit={submit}
                headerText="Complete Registration"
                handleInput={handleInput}
                username={data.username}
                password={data.password}
                passwordConfirm={data.passwordConfirm}
                processing={processing}
                completed={completed}
                tokenExpired={tokenExpired}
              />
            </Col>
          </div>
        </div>
      </div>
    );

};
export default AccountRegistration;
