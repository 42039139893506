/* eslint-disable */
import React, { FunctionComponent, JSX } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {connect} from 'react-redux';
import {checkPermission} from '../../utils/Auth/AuthService';
import BillingIcon from '../SvgIcons/MenuIcons/BillingIcon';
import CasesIcon from '../SvgIcons/MenuIcons/CasesIcon';
import ContactsIcon from '../SvgIcons/MenuIcons/ContactsIcon';
import DashboardIcon from '../SvgIcons/MenuIcons/DashboardIcon';
import MonitoringIcon from '../SvgIcons/MenuIcons/MonitoringIcon';
import OrdersIcon from '../SvgIcons/MenuIcons/OrdersIcon';
import ServicesIcon from '../SvgIcons/MenuIcons/ServicesIcon';

import DomainsIcon from '../SvgIcons/MenuIcons/DomainsIcon';
import DocumentsIcon from "../SvgIcons/MenuIcons/DocumentsIcon";
import ContractsIcon from "../SvgIcons/MenuIcons/ContractsIcon";
import WeatherMapsIcon from "../SvgIcons/MenuIcons/WeatherMapsIcon";
import ReportsIcon from '../SvgIcons/MenuIcons/ReportsIcon'
import { IUserState } from '../../store/user/types'
import { IWeatherMapState } from '../../store/weatherMap/types'

type TProps = {
  user: IUserState;
  weatherMaps: IWeatherMapState[]
}
const Sidebar: FunctionComponent<TProps> = ({user}: TProps): JSX.Element => {

  const location = useLocation();

  const handleClick = (e) => {

    e.preventDefault();
    e.target.parentElement.classList.toggle('open');

  }

  const activeRoute = (routeName) => {

    return location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';

  }

  const sidebarMinimize = (e) => {

    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');

  }

    return (

      <div className={`sidebar syntura-sidebar-dark-bg`}>
        <nav className="sidebar-nav">
          <ul className="nav">
            <li className="nav-item">
              <NavLink to="/dashboard" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}><DashboardIcon title="Dashboard"/>Dashboard</NavLink>
            </li>

            {(checkPermission('contacts.read', user.permissions) || checkPermission('contacts.write', user.permissions)) &&
            <li className="nav-item">
              <NavLink to="/contacts" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}><ContactsIcon title="Contacts"/>Contacts</NavLink>
            </li>
            }
            {(checkPermission('billing.read', user.permissions) || checkPermission('billing.write', user.permissions)) &&
            <li className="nav-item">
              <NavLink to="/billing" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}><BillingIcon title="Billing"/>Billing</NavLink>
            </li>
            }
            {(checkPermission('services.read', user.permissions) || checkPermission('services.write', user.permissions)) &&
            <li className="nav-item">
              <NavLink to="/services" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}><ServicesIcon title="Services"/>
                <span style={{marginLeft: '-5px'}}>Services</span></NavLink>
            </li>
            }
            {(checkPermission('domains.read', user.permissions) || checkPermission('domains.write', user.permissions)) &&
            <li className="nav-item">
              <NavLink to="/domains" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link d-flex align-items-end`}><DomainsIcon
                title="Domains"/><span>Domains</span></NavLink>
            </li>
            }
            {(checkPermission('orders.read', user.permissions) || checkPermission('orders.write', user.permissions)) &&
            <li className="nav-item">
              <NavLink to="/orders" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}><OrdersIcon
                title="Orders"/>Orders</NavLink>
            </li>
            }
            {(checkPermission('cases.read', user.permissions) || checkPermission('cases.write', user.permissions)) &&
            <li className="nav-item">
              <NavLink to="/cases" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}><CasesIcon title="Cases"/>
                Cases</NavLink>
            </li>
            }
            {checkPermission('monitoring.read', user.permissions) &&
            <li className="nav-item">
              <NavLink to="/monitoring" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}><MonitoringIcon
                title="Monitoring"/>Monitoring</NavLink>
            </li>
            }
            {user.weatherMaps?.length ? (
              <li className="nav-item">
                <NavLink to="/weather-maps" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}><WeatherMapsIcon
                  title="Weather Maps"/>Weather Maps</NavLink>
              </li>
            ) : ''}
            {checkPermission('proposals.read', user.permissions) || checkPermission('reports.read', user.permissions) ? (
              <li className="nav-item">
                <NavLink to="/documents" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}>
                  <DocumentsIcon title="Documents"/>Documents
                </NavLink>
              </li>
            ) : ''}
            {checkPermission('contracts.read', user.permissions) ? (
              <li className="nav-item">
                <NavLink to="/signatures" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}>
                  <ContractsIcon title="Contracts"/>Contracts
                </NavLink>
              </li>
            ) : ''}
            {checkPermission('reports.write', user.permissions) ? (
            <li className="nav-item">
              <NavLink to="/reports" className={({ isActive }) => `${isActive ? 'active' : ''} nav-link`}><ReportsIcon
                title="Reports"/>Reports</NavLink>
            </li>
            ) : ''}
          </ul>
        </nav>
      </div>
    );

}

function mapStateToProps({user, weatherMaps: weatherMaps}) {

  return {
    user,
    weatherMaps: weatherMaps
  };

}

export default connect(mapStateToProps)(Sidebar);
