/* eslint-disable */
import React, { FunctionComponent, useState } from 'react'
import { Card, CardBody, Label, Alert, Row, Col, FormGroup, Input, Button } from 'reactstrap'
import LaddaButton, { ZOOM_OUT } from 'react-ladda'
import { Link, useParams } from 'react-router-dom'
import SimpleLoader from '../../components/SimpleLoader'
import { GenericNotFound } from '../../components/Errors/Errors'
import { api_resetPasswordWithToken } from '../../utils/Auth/AuthService'
import Section from '../../components/Section'
import Tick from '../../components/SvgIcons/Tick'
import { PASSWORD_POLICY_REGEX } from '../../helpers/password'
import { ButtonType } from '../../components/SynturaBtn'
import { ButtonSize } from '../../components/SynturaBtn'
import SynturaBtn from '../../components/SynturaBtn'
import SynturaLogo from '../../components/SVGS/logo'

const ResetPasswordWithToken: FunctionComponent = () => {

    const params = useParams()
    type TData = {
        password: string;
        confirmPassword: string;
    }
    const [data, setData] = useState<TData>({
        password: '',
        confirmPassword: '',
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [token, setToken] = useState<string | undefined>(params.token)
    const [noMatch, setNoMatch] = useState<boolean>(false)
    const [invalid, setInvalid] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [success, setSuccess] = useState<boolean>(false)

    const handleInput = (event) => {

        setNoMatch(false)
        setInvalid(false)
        setData(prevState => ({ ...prevState, [event.target.id]: event.target.value }))

    }

    const handleSubmit = () => {

        setLoading(true)
        setError(null)
        if (data.password !== data.confirmPassword) {

            setNoMatch(true)
            setLoading(false)

        } else if (!PASSWORD_POLICY_REGEX.test(data.password)) {

            setInvalid(true)
            setLoading(false)

        } else {

            if (token) {
                api_resetPasswordWithToken(
                    token,
                    data.password,
                )
                    .then((result) => {

                        if (result.status === 200) {

                            setSuccess(true)

                        } else {

                            setError(result.data.error)

                        }
                        setLoading(false)

                    })
            }

        }

    }

    if (!token) {

        return <GenericNotFound />

    }
    return (
        <div className='app flex-row align-items-center animated fadeIn syntura-dark-bg'>
            <div className='container'>
                <div className='justify-content-center'>
                    <Row className='justify-content-center'>
                        <Col md={8}>
                            <Card className='white-card-bg' style={{ borderRadius: '10px' }}>
                                <CardBody>
                                    <Row>
                                        <Col className='p-3 mb-4'>
                                            <SynturaLogo textFill="#000" />
                                        </Col>
                                    </Row>
                                    {success ?
                                        <Row>
                                            <Col>
                                                <div className='mb-4'>
                                                    <div style={{ fontWeight: 600 }}
                                                        className='h4 text-dark text-center'>Your password has been
                                                        reset.
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-center'><Tick
                                                    cssClass='large-green-tick' /></div>
                                                <div className='mt-4'>
                                                    <Link to='/login'>
                                                        <SynturaBtn
                                                            size={ButtonSize.SMALL}
                                                            variant={ButtonType.PRIMARY}
                                                            style={{ float: 'right' }}
                                                        >
                                                            Login
                                                        </SynturaBtn>
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                        :
                                        <Section title='Reset Password'>
                                            <SimpleLoader loading={loading}>
                                                <Row className='d-flex justify-content-center'>
                                                    {error && (
                                                        <Col md={12}>
                                                            <Alert color='danger'>
                                                                {error}
                                                            </Alert>
                                                        </Col>
                                                    )}
                                                    {success && (
                                                        <Col md={12}>
                                                            <Alert color='success'>
                                                                Password
                                                                successfully
                                                                updated. Click{' '}
                                                                <Link to='/login'>
                                                                    here
                                                                </Link>{' '}
                                                                to login
                                                            </Alert>
                                                        </Col>
                                                    )}
                                                    <Col>
                                                        <FormGroup className='row'>
                                                            <Label className='col-sm-4 col-form-label'
                                                                for='firstName'>
                                                                New Password
                                                            </Label>
                                                            <div className='col-sm-8'>
                                                                <Input
                                                                    id='password'
                                                                    type='password'
                                                                    value={data.password}
                                                                    className={noMatch || invalid ? 'is-invalid' : ''}
                                                                    onChange={handleInput}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className='row'>
                                                            <Label className='col-sm-4 col-form-label'
                                                                for='newPasswordConfirm'>
                                                                Confirm New Password
                                                            </Label>
                                                            <div className='col-sm-8'>
                                                                <Input
                                                                    id='confirmPassword'
                                                                    type='password'
                                                                    className={noMatch || invalid ? 'is-invalid' : ''}
                                                                    value={data.confirmPassword}
                                                                    onChange={handleInput}
                                                                />
                                                                {noMatch && (
                                                                    <div className='invalid-feedback'>
                                                                        Password don't match
                                                                    </div>
                                                                )}
                                                                {invalid && (
                                                                    <div className='invalid-feedback'>
                                                                        Invalid password
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <small>
                                                                    Must be between 14 to 64 characters long,
                                                                    contain at least one (1)
                                                                    character from the following categories:
                                                                </small>
                                                                <ul className='small pl-3'>
                                                                    <li>
                                                                        Uppercase letter (A-Z)
                                                                    </li>
                                                                    <li>
                                                                        Lowercase letter (a-z)
                                                                    </li>
                                                                    <li>
                                                                        Digit (0-9)
                                                                    </li>
                                                                    <li>
                                                                        Special Characters (!@%#$&)
                                                                    </li>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col
                                                            >
                                                                <SynturaBtn
                                                                    onClick={handleSubmit}
                                                                    size={ButtonSize.SMALL}
                                                                    variant={ButtonType.PRIMARY}
                                                                    style={{ float: 'right' }}
                                                                >
                                                                    Submit
                                                                </SynturaBtn>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </SimpleLoader>
                                        </Section>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
export default ResetPasswordWithToken
