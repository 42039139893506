import React from "react";

const SynturaLogo = ({
  textFill = "white"
}) => {
  return (
    <svg width="220" height="45" viewBox="0 0 220 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_865_10887)">
        <path d="M56.7133 26.0617H61.3952C61.5141 28.7091 64.5466 30.0936 67.6098 30.0936C70.1424 30.0936 73.2342 29.1529 73.2342 27.1502C73.2342 22.3234 57.3299 25.1497 57.3299 17.5872C57.3299 13.8799 61.4238 11.7314 67.0196 11.7314C72.9391 11.7314 77.1498 14.2332 77.2973 18.6161H72.6727C72.5251 16.0857 69.7856 14.8205 66.9602 14.8205C64.4276 14.8205 61.8951 15.6154 61.8951 17.3796C61.8951 21.9105 77.9161 19.2035 77.9161 27.031C77.9161 31.0033 73.2629 33.2687 67.5195 33.2687C61.4811 33.2687 56.7706 30.6213 56.7111 26.0595L56.7133 26.0617Z" fill={textFill} />
        <path d="M77.1691 12.1732V12.0562H82.2342L86.0924 17.9715L88.037 21.2085H88.4202L90.3647 17.9715L94.223 12.0562H99.288V12.1732L90.4528 24.7083V32.9465H86.0066V24.737L77.1713 12.1732H77.1691Z" fill={textFill} />
        <path d="M103.186 12.0562H108.81L115.613 24.7679L117.734 29.0338H118.971V12.0562H123.153V32.9487H115.349L108.603 20.237L106.483 15.971H105.245V32.9487H101.034V14.1759C101.034 12.9394 101.948 12.0584 103.184 12.0584L103.186 12.0562Z" fill={textFill} />
        <path d="M133.601 15.4099H125.354V12.0559H146.323V15.4099H138.047V32.9462H133.601V15.4099Z" fill={textFill} />
        <path d="M148.512 24.973V12.0559H152.958V25.0922C152.958 28.5942 155.934 29.9477 159.085 29.9477C162.236 29.9477 165.211 28.5942 165.211 25.0922V12.0559H169.658V24.973C169.658 28.6516 167.066 33.2708 159.085 33.2708C151.104 33.2708 148.512 28.6516 148.512 24.973Z" fill={textFill} />
        <path d="M174.718 12.0559H184.053C189.178 12.0559 192.301 14.381 192.301 18.3223C192.301 21.354 190.356 23.5311 186.881 24.2664L188.59 26.4148L193.596 32.9462H188.295L182.199 24.5601H176.987V32.9462H172.54V14.1734C172.54 12.9369 173.454 12.0559 174.721 12.0559H174.718ZM183.996 21.4423C186.5 21.4423 187.619 20.2367 187.619 18.2936C187.619 16.3506 186.205 15.262 183.642 15.262H176.987V21.4423H183.996Z" fill={textFill} />
        <path d="M203.096 12.0559H210.548L220.002 32.9485H215.142L212.402 26.6511H201.151L198.412 32.9485H193.64L203.094 12.0559H203.096ZM211.017 23.4737L209.104 19.0598L207.483 15.1759H206.098L204.477 19.0598L202.563 23.4737H211.015H211.017Z" fill={textFill} />
        <path d="M29.1924 31.7362C29.1924 32.4008 29.4567 33.0036 29.8817 33.4474H29.8795L36.4685 40.0561L39.9479 36.5674L33.4162 30.0205C33.3986 30.0028 33.381 29.9852 33.3634 29.9675L33.3568 29.9609C32.9141 29.5325 32.3129 29.2698 31.65 29.2698C30.2913 29.2698 29.1902 30.3738 29.1902 31.7362H29.1924Z" fill="url(#paint0_linear_865_10887)" />
        <path d="M15.7039 13.2726C15.7039 12.6079 15.4397 12.0051 15.0124 11.5613H15.0146L8.42565 4.95264L4.94617 8.44135L11.4779 14.9904C11.4955 15.0081 11.5109 15.0257 11.5285 15.0412L11.5352 15.0478C11.9778 15.4762 12.579 15.7389 13.2419 15.7389C14.6006 15.7389 15.7017 14.6349 15.7017 13.2726H15.7039Z" fill="url(#paint1_linear_865_10887)" />
        <path d="M31.6522 15.7192C32.3151 15.7192 32.9163 15.4542 33.359 15.028H33.3612L39.9501 8.42377L36.4707 4.93506L29.9411 11.4841C29.9235 11.5018 29.9059 11.5195 29.8883 11.5371L29.8817 11.5437C29.4544 11.9876 29.1902 12.5904 29.1902 13.255C29.1902 14.6173 30.2913 15.7214 31.65 15.7214L31.6522 15.7192Z" fill="url(#paint2_linear_865_10887)" />
        <path d="M13.2374 29.2676C12.5745 29.2676 11.9733 29.5325 11.5307 29.9609H11.5285L4.93951 36.5652L8.41899 40.0539L14.9507 33.5048C14.9683 33.4872 14.986 33.4717 15.0014 33.454L15.008 33.4474C15.4352 33.0036 15.6995 32.4008 15.6995 31.7362C15.6995 30.3738 14.5984 29.2698 13.2396 29.2698L13.2374 29.2676Z" fill="url(#paint3_linear_865_10887)" />
        <path d="M19.985 0V13.5442C19.985 17.1212 17.0825 20.0292 13.5171 20.0292H0V24.9487H13.5171C16.5562 24.9487 19.4124 23.763 21.5618 21.6079C23.7111 19.4529 24.8937 16.5891 24.8937 13.542V0" fill="url(#paint4_linear_865_10887)" />
        <path d="M24.8937 45.0001V31.4427C24.8937 27.8656 27.7962 24.9554 31.3638 24.9554H44.8831V20.0337H31.3638C28.3247 20.0337 25.4685 21.2194 23.3191 23.3745C21.1698 25.5295 19.9872 28.3934 19.9872 31.4405V44.9979" fill="url(#paint5_linear_865_10887)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_865_10887" x1="-0.638637" y1="34.6618" x2="39.6044" y2="34.6618" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00D2FF" />
          <stop offset="0.4" stopColor="#2E8DF8" />
          <stop offset="0.81" stopColor="#594CF1" />
          <stop offset="1" stopColor="#6B33EF" />
        </linearGradient>
        <linearGradient id="paint1_linear_865_10887" x1="5.20382" y1="10.3469" x2="45.4447" y2="10.3469" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00D2FF" />
          <stop offset="0.4" stopColor="#2E8DF8" />
          <stop offset="0.81" stopColor="#594CF1" />
          <stop offset="1" stopColor="#6B33EF" />
        </linearGradient>
        <linearGradient id="paint2_linear_865_10887" x1="-13.5215" y1="10.3271" x2="82.446" y2="10.3271" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00D2FF" />
          <stop offset="0.4" stopColor="#2E8DF8" />
          <stop offset="0.81" stopColor="#594CF1" />
          <stop offset="1" stopColor="#6B33EF" />
        </linearGradient>
        <linearGradient id="paint3_linear_865_10887" x1="-37.7612" y1="34.6596" x2="58.2064" y2="34.6596" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00D2FF" />
          <stop offset="0.4" stopColor="#2E8DF8" />
          <stop offset="0.81" stopColor="#594CF1" />
          <stop offset="1" stopColor="#6B33EF" />
        </linearGradient>
        <linearGradient id="paint4_linear_865_10887" x1="-6.58238" y1="12.4755" x2="60.5958" y2="12.4755" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00D2FF" />
          <stop offset="0.4" stopColor="#2E8DF8" />
          <stop offset="0.81" stopColor="#594CF1" />
          <stop offset="1" stopColor="#6B33EF" />
        </linearGradient>
        <linearGradient id="paint5_linear_865_10887" x1="-15.6334" y1="32.518" x2="51.2805" y2="32.518" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00D2FF" />
          <stop offset="0.4" stopColor="#2E8DF8" />
          <stop offset="0.81" stopColor="#594CF1" />
          <stop offset="1" stopColor="#6B33EF" />
        </linearGradient>
        <clipPath id="clip0_865_10887">
          <rect width="220" height="45" fill={textFill} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SynturaLogo;