import * as React from 'react';
import { NavbarBrand, NavbarToggler } from 'reactstrap';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUserState } from '../../store/user/types';
import { IHeaderState } from '../../store/header/types';
import { IAccountState } from '../../store/account/types';
import SupportMailPhoneLinks from '../../components/Widgets/SupportMailPhoneLinks';
import UserDropdown from '../../components/UserDropdown';
import { toggleUserDropDown } from '../../store/header/actions';
import Hamburger from '../../components/SvgIcons/Hamburger';
import { logout } from '../../store/user/actions';
import { ISystemState } from '../../store/system/types';
import { resetSystemPartnerCustomer, setSystemPartnerCustomer } from '../../store/system/actions';
import { getAccountContacts, resetContactsStore, toggleContactsLoading } from '../../store/contacts/actions';
import { getAccountOrders, resetOrdersStore, toggleOrdersLoading } from '../../store/orders/actions';
import {
  getAccountServices,
  getCustomerServiceGroups,
  getServiceGroups,
  resetServicesStore,
  toggleServicesLoading,
} from '../../store/services/actions';
import { getAccountCases, resetCasesStore, toggleAccountCasesLoading } from '../../store/cases/actions';
import {
  getMonitoredItems,
  getMonitoredItemStatuses,
  resetMonitoringStore,
  toggleMonitoredItemsLoading,
  toggleMonitoredItemsStatusLoading,
} from '../../store/monitoring/actions';
import {
  getOpenCases,
  resetDashboard,
  toggleAccountSummaryLoading,
  toggleCasesLoading,
} from '../../store/dashboard/actions';
import { getAccountSummary } from '../../store/account/actions';
import Download from '../../components/SvgIcons/Download';
import { AppDispatch } from '../../configureStore';

type TOwnProps = {
  header: IHeaderState;
  account: IAccountState;
  user: IUserState;
  system: ISystemState;
  dispatch: AppDispatch;
};
type TProps = TOwnProps;
const Header: React.FC<TProps> = ({
  account, user, header, dispatch, system,
}) => {

  const { hsoSupport: supportPhone, name: companyName, customers } = account;
  const { username, email: userEmail } = user;
  const { dropdownOpen } = header;
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropDown = () => {

    dispatch(toggleUserDropDown());
  
  };
  const handleLogout = () => {

    dispatch(logout()).then(() => {

      navigate('/login?exp=1');
    
    });
  
  };
  const mobileSidebarToggle = (e) => {

    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  
  };
  const hideCustomerDropDown = () => {

    const whiteList = ['/dashboard', '/contacts', '/services', '/orders', '/cases', '/monitoring'];
    return whiteList.filter((path) => path === location.pathname).length === 0;
  
  };
  const partnerCustomerOptions = customers.map((partner) => ({ label: partner.name, value: partner.accountNumber }));
  const handlePartnerSelect = (selected) => {

    if (selected) {

      const customerFound = account.customers.filter((customer) => customer.accountNumber === selected.value);
      dispatch(setSystemPartnerCustomer(customerFound[0], user.favourites));
    
    } else {

      dispatch(resetSystemPartnerCustomer());
    
    }

    if (location.pathname === '/dashboard') {

      dispatch(resetDashboard());
      dispatch(toggleAccountSummaryLoading());
      dispatch(toggleCasesLoading());
      dispatch(getOpenCases()).then(() => dispatch(toggleCasesLoading()));
      dispatch(getAccountSummary()).then(() => {

        dispatch(toggleAccountSummaryLoading());
      
      });
    
    }
    if (location.pathname === '/contacts') {

      dispatch(resetContactsStore());
      dispatch(toggleContactsLoading());
      dispatch(getAccountContacts()).then(() => dispatch(toggleContactsLoading()));
    
    }
    if (location.pathname === '/orders') {

      dispatch(resetOrdersStore());
      dispatch(toggleOrdersLoading());
      dispatch(getAccountOrders()).then(() => dispatch(toggleOrdersLoading()));
    
    }
    if (location.pathname === '/cases') {

      dispatch(resetCasesStore());
      dispatch(toggleAccountCasesLoading());
      dispatch(getAccountCases()).then(() => dispatch(toggleAccountCasesLoading()));
    
    }
    if (location.pathname === '/services') {

      dispatch(resetServicesStore());
      dispatch(toggleServicesLoading());
      dispatch(getServiceGroups()).then((result) => {

        if (result) {

          dispatch(getAccountServices()).then(() => dispatch(toggleServicesLoading())).then((result2) => {

            if (result2) {

              dispatch(getCustomerServiceGroups());
            
            }
          
          });
        
        } else {

          dispatch(toggleServicesLoading());
        
        }
      
      });
    
    }
    if (location.pathname === '/monitoring') {

      dispatch(resetMonitoringStore());
      dispatch(toggleMonitoredItemsLoading());
      dispatch(getMonitoredItems()).then(() => {

        dispatch(toggleMonitoredItemsLoading());
        dispatch(toggleMonitoredItemsStatusLoading());
        dispatch(getMonitoredItemStatuses()).then(() => dispatch(toggleMonitoredItemsStatusLoading()));
      
      });
    
    }
  
  };
  return (
    <header data-testid="header" className="app-header navbar">
      <NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle}>
        <Hamburger />
      </NavbarToggler>
      <div className="mr-auto d-none d-lg-block">
        <NavbarBrand className={`${process.env.REACT_APP_ENV !== 'prod' ? 'bg-syntura-dark' : ''} d-md-down-none`} />
      </div>
      {customers.length > 0
    && (
    <div className="mr-3 ml-auto" style={{ width: '300px' }}>

      <Select
        options={partnerCustomerOptions}
        isSearchable
        isClearable
        placeholder="Select a customer to view"
        onChange={handlePartnerSelect}
        isDisabled={hideCustomerDropDown()}
      />

    </div>
    )}
      <div className="mr-3 ml-auto text-dark">
        <strong> Contact support</strong>
      </div>
      <div className="mr-3 text-dark">
        <SupportMailPhoneLinks supportPhone={supportPhone} />
      </div>
      {process.env.REACT_APP_ENV !== 'prod' ? (
        <div className="mr-3 text-dark cursor-pointer" title="Copy token to clipboard" onClick={() => navigator.clipboard.writeText(localStorage.getItem('hso-api-token') || '')}>
          <Download />
        </div>
      ) : ''}
      <div className="pl-2" style={{ borderLeft: '1px solid #ddd' }}>
        <UserDropdown
          logout={handleLogout}
          handleToggle={toggleDropDown}
          companyName={companyName}
          username={username}
          dropdownOpen={dropdownOpen}
          userEmail={userEmail}
        />
      </div>
    </header>
  );

};
export default Header;
