import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import _ from 'underscore';
import { Button, Col, Row } from 'reactstrap';
import {
  AreaChart, XAxis, ResponsiveContainer, Legend, Tooltip, YAxis, Area, ReferenceArea,
} from 'recharts';
import SimpleLoader from '../SimpleLoader';
import GraphCal from '../SvgIcons/GraphCal';
import { TTrafficData } from '../../store/monitoring/types';
import { formatbps, formatCompactNumber } from '../../helpers/FormatData/formatData';

type TOwnProps = {
  loading: boolean;
  dateRangeHandler?: typeof DateRangePicker.dateRangeHandler;
  startDate?: Moment;
  endDate?: Moment;
  dataIn: TTrafficData;
  dataOut: TTrafficData;
  name?: string;
  unit?: string;
  inboundLabel?: string;
  outboundLabel?: string;
};

const TrafficGraph = ({
  loading,
  unit,
  dateRangeHandler,
  startDate,
  endDate,
  dataIn,
  dataOut,
  inboundLabel,
  outboundLabel,
}: TOwnProps): JSX.Element => {

  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };
  const inMax = _.max(dataIn.data.map(([t, v]) => v));
  const outMax = _.max(dataOut.data.map(([t, v]) => v));
  const max = _.max([inMax, outMax]);
  const start = startDate?.format('DD/MM/YY');
  const end = endDate?.format('DD/MM/YY');
  const numberOfDays = startDate && endDate && (endDate.diff(startDate, 'days'));
  const label = (start === end) ? start : `${start} - ${end}`;

  type TItem = {
    timestamp: number;
    in?: number;
    out?: number;
  };
  const initialData: TItem[] = dataIn.data.map(([timestamp, value], index) => {

    const graphItem: TItem = {
      timestamp: Number(timestamp),
    };

    if (value !== null) {

      graphItem.in = Math.round((Number(value) + Number.EPSILON) * 1000) / 1000;

    }
    const out = dataOut.data.find(([outTimestamp, outValue]) => outTimestamp === timestamp);
    if (out !== undefined && out[1] !== null) {

      graphItem.out = Math.round((Number(-out[1]) + Number.EPSILON) * 1000) / 1000;

    }
    return graphItem;

  });

  const [graphData, setGraphData] = useState<{
    data: typeof initialData;
    left: string | number;
    right: string | number;
    refAreaLeft: string;
    refAreaRight: string;
    bottom: number;
    top: number;
    animation: boolean;
  }>({
    data: initialData,
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: '',
    refAreaRight: '',
    bottom: 0,
    top: 0,
    animation: true,
  });

  useEffect(() => {

    getStartData();

  }, [max]);

  useEffect(() => {

    getStartData();

  }, [initialData[0]?.timestamp, initialData[initialData.length]?.timestamp]);

  const getAxisYDomain = (from, to) => {

    const refData = initialData.filter(({ timestamp }) => timestamp >= from && timestamp < to);

    const maxIn = Math.max(...refData.map((datum) => (datum.in ? Math.abs(datum.in) : 0)));
    const maxOut = Math.max(...refData.map((datum) => (datum.out ? Math.abs(datum.out) : 0)));

    const absMax = Math.max(Math.abs(maxIn), Math.abs(maxOut));

    return [-absMax, absMax];

  };

  const getStartData = () => {

    const startDateUnix = Math.min(...initialData.map(({ timestamp }) => timestamp));
    const endDateUnix = Math.max(...initialData.map(({ timestamp }) => timestamp));

    const [bottom, top] = getAxisYDomain(startDateUnix, endDateUnix);

    setGraphData((prevState) => ({
      ...prevState,
      data: initialData,
      left: 'dataMin',
      right: 'dataMax',
      refAreaLeft: '',
      refAreaRight: '',
      bottom,
      top,
    }));

  };

  const zoom = () => {

    let { refAreaLeft, refAreaRight } = graphData;
    const { data } = graphData;

    if (refAreaLeft === refAreaRight || refAreaRight === '') {

      setGraphData((prevState) => ({
        ...prevState,
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;

    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight);

    setGraphData((prevState) => ({
      ...prevState,
      data: data.slice(),
      refAreaLeft: '',
      refAreaRight: '',
      left: Number(refAreaLeft),
      right: Number(refAreaRight),
      bottom,
      top,
    }));

  };

  const revert = () => {

    getStartData();

  };

  return (
    <div className="d-flex flex-column rounded bg-white py-3" style={{ minHeight: '300px' }}>
      <SimpleLoader loading={loading}>
        {graphData.left !== 'dataMin' ? (
          <Row className="mt-auto mr-0 ml-0 mb-3">
            <Col className="d-flex justify-content-end">
              <Button onClick={revert}><i title="revert" className="fa fa-undo" /></Button>
            </Col>
          </Row>
        ) : ''}
        <Row className="px-3 user-select-none">
          <Col>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={graphData.data}
                onMouseDown={(e: any) => setGraphData((prevState) => ({
                  ...prevState,
                  refAreaLeft: (e?.activeLabel || ''),
                }))}
                onMouseMove={(e: any) => graphData.refAreaLeft && setGraphData((prevState) => ({
                  ...prevState,
                  refAreaRight: (e?.activeLabel || ''),
                }))}
                onMouseUp={zoom}
              >
                <XAxis
                  dataKey="timestamp"
                  type="number"
                  scale="linear"
                  domain={[graphData.left, graphData.right]}
                  minTickGap={30}
                  tickFormatter={(timestamp) => (timestamp ? moment(timestamp, 'X').format(numberOfDays && numberOfDays > 1 ? 'DD/MM hA' : 'HH:mm') : ' -- ')}
                  allowDataOverflow
                />
                <YAxis
                  label={{
                    value: `Traffic (${unit ?? 'bps'})`, angle: -90, position: 'insideRight', size: 14,
                  }}
                  domain={[graphData.bottom, graphData.top]}
                  orientation="right"
                  tickFormatter={(value) => value && `${formatCompactNumber(Math.abs(value))}`}
                  allowDecimals={false}
                  scale="linear"
                  tickCount={20}
                  allowDataOverflow
                />
                <Legend />
                <Tooltip
                  labelFormatter={(label) => moment(label, 'X').format('DD/MM/YY HH:mm')}
                  formatter={(value) => [formatbps(Math.abs(Number(value)))]}
                />
                <Area
                  dataKey="in"
                  name={inboundLabel || dataIn.label}
                  type="linear"
                  fill="#66e4ff"
                  stroke="#66e4ff"
                  fillOpacity={0.9}
                />
                <Area
                  dataKey="out"
                  name={outboundLabel || dataOut.label}
                  type="linear"
                  fill="#9066f3"
                  stroke="#9066f3"
                  fillOpacity={0.9}
                />
                {graphData.refAreaLeft && graphData.refAreaRight ? (
                  <ReferenceArea x1={graphData.refAreaLeft} x2={graphData.refAreaRight} strokeOpacity={0.3} />
                ) : null}
              </AreaChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between mt-auto mr-0 ml-0">
          {dateRangeHandler ? (
            <Col className="d-flex justify-content-end">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                opens="left"
                drops="up"
                ranges={ranges}
                onEvent={dateRangeHandler}
                dateLimit={{ months: 2 }}
                locale={{ format: 'DD/MM/YYYY' }}
              >
                <Button className="btn-datepicker bg-white d-flex justify-content-between">
                  <span>{label}</span>
                  <div style={{ height: '20px', width: '20px' }} className="ml-2">
                    <GraphCal />
                  </div>
                </Button>
              </DateRangePicker>
            </Col>
          ) : ''}
        </Row>
      </SimpleLoader>
    </div>
  );

};

export default TrafficGraph;
