import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Col, Row, CardBody, Card, Collapse,
} from 'reactstrap';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Section from '../../components/Section';
import { IOrderState } from '../../store/order/types';
import {
  getOrderActivities,
  getOrderData,
  setActivitiesState,
  toggleActivityState,
  toggleOrderActivitiesLoading,
  toggleOrderLoading,
  resetOrderState,
  getOrderActivityContent,
  toggleOrderActivityContentLoading,
} from '../../store/order/action';
import TextInput from '../../components/Inputs/TextInput';
import ContactCard from '../../components/Widgets/ContactCard';
import { IAccountState } from '../../store/account/types';
import { Gravatar } from '../../components/Gravatar/Gravatar';
import SimpleLoader from '../../components/SimpleLoader';
import ComplexTable from '../../components/Table/ComplexTable';
import { AppDispatch } from '../../configureStore';

type TParams = { id: string };
type TOwnProps = {
  order: IOrderState;
  account: IAccountState;
  dispatch: AppDispatch;
};
type TProps = TOwnProps;
const getEmailType = (type, email) => {

  switch (type) {
    case 'Email':
      if (email && email.match(/(syntura.io|hso.uk.com|goscomb.net)/)) {

        return { cssClass: 'accord--email-inbound', label: 'Inbound email' };
      
      }
      return { cssClass: 'accord--email-outbound', label: 'Outbound email' };
    case 'Phone Call':
      return { cssClass: 'accord--phone', label: 'Phone' };
    case 'Case Resolution':
      return { cssClass: 'accord--resolved', label: 'Resolved' };
    default:
      return { cssClass: '', label: '' };
  }

};
const Order: React.FC<TProps> = (props) => {

  const {
    loading, data, activities, loadingActivities,
  } = props.order;

  const params = useParams();

  useEffect(() => {

    props.dispatch(resetOrderState());
    props.dispatch(toggleOrderLoading());
    props.dispatch(getOrderData(params.id)).then(() => {

      props.dispatch(toggleOrderLoading());
    
    });
    props.dispatch(toggleOrderActivitiesLoading());
    props.dispatch(getOrderActivities(params.id)).then(() => {

      props.dispatch(setActivitiesState());
      props.dispatch(toggleOrderActivitiesLoading());
    
    });
  
  }, []);
  const createMarkup = (markup) => ({ __html: markup });
  const toggleActivityStateHandler = (id) => {

    const activity = activities.filter((activity) => id === activity.id)[0];
    if (!activity.showFullMessage && !activity.content) {

      props.dispatch(toggleOrderActivityContentLoading(id));
      props.dispatch(getOrderActivityContent(data.orderNumber, id)).then(() => props.dispatch(toggleOrderActivityContentLoading(id)));
    
    }
    props.dispatch(toggleActivityState(id));
  
  };

  const columns = [
    {
      Header: 'Product Number',
      accessor: 'product.productCode',
    },
    {
      Header: 'Name',
      accessor: 'product.name',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Date Created',
      accessor: 'createdOn',
      Cell: (props) => (props.value ? moment(props.value).format('DD/MM/YYYY') : <></>),
    },
  ];
  return (
    <div className="animated fadeIn mb-5">
      <SimpleLoader loading={loading}>
        <Row>
          <Col>
            <Section title={`Order ${data.orderNumber}`}>
              <Row>
                <Col md={4} className="d-flex">
                  <div className="bg-white shadow-sm pt-4 pl-4 pb-4 d-flex flex-column w-100" style={{ borderRadius: '10px' }}>
                    <TextInput label="Order Number" id="orderNumber" value={data.orderNumber} readOnly />
                    <TextInput label="Name" id="name" value={data.name} readOnly />
                    <TextInput label="Status" id="status" value={data.status} readOnly />
                    <TextInput label="Created" id="createdAt" value={data.createdOn ? moment(data.createdOn).format('DD/MM/YYYY') : ''} readOnly />
                    <TextInput label="Project Mgr" id="createdAt" value={`${data.projectManager.firstName} ${data.projectManager.lastName}`} readOnly />
                  </div>
                </Col>
                <Col md={8} className="d-flex">
                  <div className="d-flex flex-column w-100 h-100">
                    <Card style={{ borderRadius: '10px' }} className="px-2 pt-3 mb-0 h-100">
                      <ComplexTable
                        columns={columns}
                        data={data.lineItems}
                        defaultPageSize={5}
                      />
                    </Card>
                  </div>
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12} xl={8}>
            <Section title="Email Activity">
              <SimpleLoader loading={loadingActivities}>
                <div className="card p-5 accord" style={{ borderRadius: '10px' }}>
                  {activities && activities.map((activity, index) => (
                    <SimpleLoader loading={activity.loadingContent || false} key={index}>
                      <Row
                        onClick={() => toggleActivityStateHandler(activity.id)}
                        className={` accord__bar p-3 mb-2 ${getEmailType(activity.type, activity.emailAddresses.from).cssClass}`}
                      >
                        <Col className="d-flex align-items-center" md="3">
                          <span
                            className="text-uppercase"
                          >
                            {' '}
                            {getEmailType(activity.type, activity.emailAddresses.from).label}
                            {' '}

                          </span>
                        </Col>
                        <Col className="d-flex align-items-center text-dark" md="3">
                          <span>
                            {moment(activity.modifiedOn).format('DD/MM/YYYY')}
                          </span>
                        </Col>
                        <Col className="d-flex align-items-center text-dark " md="4">
                          <span className="truncate">
                            {activity.subject}
                          </span>
                        </Col>
                        <Col className="d-flex align-items-center text-light" md="2">
                          {activity.showFullMessage && (<i className="fa fa-minus text-light ml-auto" />)}
                          {!activity.showFullMessage && (<i className="fa fa-plus text-light  ml-auto" />)}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Collapse isOpen={activity.showFullMessage}>
                            <Card style={{ boxShadow: 'none', border: 'none' }}>
                              <CardBody>
                                <Gravatar
                                  email={activity.emailAddresses.from}
                                  size={45}
                                  className="img-avatar"
                                />
                                {' '}
                                {activity.createdBy}
                                {activity.content && (
                                <div
                                  dangerouslySetInnerHTML={createMarkup(activity.content.toString())}
                                />
                                )}
                              </CardBody>
                            </Card>
                          </Collapse>
                        </Col>
                      </Row>
                    </SimpleLoader>
                  ))}
                </div>
              </SimpleLoader>
            </Section>
          </Col>
          <Col md={8} xl={4}>
            <Section title="Order Project Manager">
              <ContactCard
                firstName={data.projectManager.firstName}
                lastName={data.projectManager.lastName}
                email={data.projectManager.email}
                phone={data.projectManager.telephone}
                jobTitle="Project Manager"
                dropShadow
                emailSubject={data.orderNumber}
              />
            </Section>
          </Col>
        </Row>
      </SimpleLoader>
    </div>
  );

};
function mapStateToProps({ order, user, account }) {

  return {
    user,
    order,
    account,
  };

}
export default connect(mapStateToProps)(Order);
