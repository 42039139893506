import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import Section from '../../components/Section';
import SynturaLogo from '../../components/SVGS/logo';

const Terms: FunctionComponent = (props) => (
  <div className="app flex-row align-items-center animated fadeIn">
    <div className="container">
      <div className="row justify-content-center">
        <Col md={8} className="d-flex flex-column justify-content-center align-items-center">
          <Row>
            <Col className="p-3 mb-4">
              {/* <img
                className="img-fluid"
                alt=""
                src="../img/hso-email-header.png"
              /> */}
              <div className="img-fluid">
                <SynturaLogo textFill="#000" />
              </div>
            </Col>
          </Row>
          <h1>Terms and Conditions</h1>
          <Section title="Acceptance">
            <p className="mb-4">
              These Terms and Conditions govern the use of this Portal ('Portal'). Please read these terms and
              conditions of use (“Terms”) carefully before using the Portal. By using the Portal, you agree to be
              bound by these Terms. If you do not agree to these Terms, you must not use the Portal.
              <br />
              Syntura may modify these Terms from time to time without notice to you and such modifications are effective
              immediately. You are advised to keep up to date with any changes by regularly reviewing these Terms.
              Your continued access or use of the Portal is deemed to be your acceptance of the modified Terms.
              <br />
              The Portal may contain links to other Syntura sites or to third party sites which may be covered by separate
              terms and conditions. Any links to third party websites do not amount to any endorsement of that site by
              Syntura. Syntura has no control over third party sites and any use of that site by you is at your own risk.
              <br />
              Any Syntura products and services available through the Portal are covered by separate terms and conditions
              and prices.
            </p>
          </Section>
          <Section title="Access and Security">
            <p className="mb-4">
              Your account manager will provide the login details for the Portal. You are required to complete the
              online Portal registration process to gain access to the Portal. Syntura may verify the registration.
              <br />
              You are responsible for the security and proper use of your user IDs and passwords used in connection
              with the Portal and must take all necessary steps to ensure that they are kept confidential, secure,
              used properly and not disclosed to unauthorised people. You must not disclose any access information to
              any third parties.
              <br />
              Syntura reserves the right to suspend User ID and password access to the Site if Syntura considers that there is
              or is likely to be a breach of security.
            </p>
          </Section>
          <Section title="Availability">
            <p className="mb-4">
              Syntura does not undertake to provide a fault-free Site. Syntura makes no representations, warranties, or
              guarantees, whether express or implied, that the content on our site is accurate, complete or up to
              date. In the case of a fault Syntura will attempt to restore the Site as soon as is reasonably practicable.
            </p>
          </Section>
          <Section title="Portal Use">
            <p className="mb-4">
              The Portal is a user interface. You must ensure it is used in an appropriate and lawful manner. You may
              not:
              <br />
              <ul>
                <li>remove, change, or obscure in any way anything on the Portal</li>
                <li>reverse engineer or decompile (in whole or in part) any software used in the Portal</li>
                <li>copy or use any material from the Portal for any commercial purpose</li>
              </ul>
              The content on our Portal is provided for general information only. It is not intended to amount to
              advice on which you should rely.
              <br />
              Syntura reserves the right to suspend or cancel your registration if you permit or make any attempt to
              disassemble, deconstruct, break down, modify, reformat, copy, replicate, hack or otherwise interfere
              with the Portal or any parts of its content.
            </p>
          </Section>
          <Section title="Suspension, Variation and Termination">
            <p className="mb-4">
              Syntura reserves the right to modify, edit, delete, suspend or discontinue the content, materials, products
              and/or technical specification of any aspect of the Portal at any time and from time to time at its sole
              discretion.
              <br />
              Syntura may elect to suspend, restrict, vary, or terminate the Portal access immediately and without prior
              notice at any time for repair or maintenance work or in order to upgrade or update the Portal or for any
              other reason at its sole discretion. Syntura will make every attempt to notify you in advance if any
              upgrades or updates occur.
              <br />
              Your access to the Portal is valid only while you are a customer of Syntura. On termination of our services,
              your access to the Portal will be terminated.
              <br />
              Syntura reserves the right to or suspend or terminate the Portal, if in our reasonable opinion you have
              failed to comply with any of the provisions of these Terms.
            </p>
          </Section>
          <Section title="Liability">
            <p className="mb-4">
              Syntura is not liable in contract, tort (including negligence), breach of statutory duty or otherwise for
              loss whether direct or indirect of business, revenue or profits, anticipated savings or wasted
              expenditure, corruption or destruction of data or for any indirect or consequential loss resulting from
              your use of or inability to use the Portal, or from your relying on the accuracy or completeness of the
              Portal content.
              <br />
              Syntura will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack,
              or other technologically harmful material that may infect your computer equipment, computer programs,
              data or other proprietary material due to your use of the Portal and/or any information downloaded from
              it.
              <br />
              You agree to indemnify and hold Syntura harmless from and against all loss, damages, costs and expenses
              arising or incurred in respect of any actions, legal proceedings or claims brought or threatened by a
              third party resulting from your use of the Portal or your breach or non-observance of any of these Terms
              or from breach of Clause 9.
              <br />
              Syntura will not be liable for any breach of these Terms caused by circumstances beyond Syntura's reasonable
              control.
            </p>
          </Section>
          <Section title="Agency">
            <p className="mb-4">
              You acknowledge that any parties entered as Contacts in the Contacts section of the portal that are not
              employees of the customer shall be Agents of the customer and will have access to the customer details
              contained in the portal, will be authorised to act on behalf of the customer, to take decisions, actions
              and instruct Syntura on the customers behalf. Syntura shall be authorised to take instruction from the Agent and
              provide the Agent with full disclosure of information which may include, but is not limited to, personal
              data as required.
              <br />
              This authorisation is not considered to create a joint venture, partnership, employment relationship,
              franchise or any other legal relationship between the customer and Syntura or the Agent and Syntura.
              <br />
              The Customer shall indemnify Syntura for any damages, losses, claims, and demands against Syntura arising out of
              or as a result of the acts, omissions, or instructions of the Agent.
              <br />
              In no event shall Syntura be liable to the Agent for incidental, special, indirect or consequential damages
              including lost revenues, profits or other economic loss arising out of this authorisation, and Syntura makes
              no warranties to the Agent with respect to service either express or implied, including without
              limitation warranties of merchantability, fitness for particular purpose or warranties arising from
              trade, custom or usage.
            </p>
          </Section>
          <Section title="Waiver">
            <p className="mb-4">
              Any delay, forbearance or indulgence in acting upon a breach of these Terms by Syntura will not be regarded
              as a waiver of that breach. Any waiver of a breach of these Terms by Syntura will be limited to that breach
              only.
            </p>
          </Section>
          <Section title="Confidentiality">
            <p className="mb-4">
              You will keep in confidence any information of a confidential nature obtained because of the use of the
              Portal. You will not disclose that information to any person other than employees or professional
              advisers.
            </p>
          </Section>
          <Section title="Copyright and Proprietary Rights">
            <p className="mb-4">
              The content of the Portal is protected by copyright, trademark and other intellectual property rights
              and proprietary rights and laws as applicable. All rights are expressly reserved.
              <br />
              Nothing on the Portal shall be construed as conferring any licence or other transfer of rights to you of
              any intellectual property or other proprietary rights of Syntura, whether by estoppel, implication or
              otherwise.
            </p>
          </Section>
          <Section title="Data Protection">
            <p className="mb-4">
              Syntura will comply with its obligations under applicable data protection legislation in operating the
              Portal. For further details see
              {' '}
              <a href="https://www.syntura.io/legal/privacy-policy" target="_blank" rel="noreferrer">
                Syntura’s
                Privacy Policy
              </a>
              .
            </p>
          </Section>
          <Section title="Virus Protection">
            <p className="mb-4">
              You must take all reasonable steps to ensure that any software you use with or in connection with the
              Portal is not infected by viruses. Syntura will take all reasonable steps to ensure that the Portal is not
              infected by viruses.
            </p>
          </Section>
          <Section title="Invalidity">
            <p className="mb-4">
              Any provisions of these Terms which is held invalid, illegal or unenforceable by any court of competent
              jurisdiction will be severed, the remaining provisions will continue in full force and effect.
            </p>
          </Section>
          <Section title="Transfer of Rights and Third-Party Rights">
            <p className="mb-4">
              You may not transfer any of your rights or obligations under these Terms to another party.
              <br />
              A person who is not party to these Terms has no right under the Contracts (Rights of Third Parties) Act
              1999 to enforce any of these Terms.
            </p>
          </Section>
          <Section title="Applicable Law">
            <p className="mb-4">
              These Terms or claims arising out of or in connection with them are governed and construed in accordance
              with the law of England and Wales.
            </p>
          </Section>
        </Col>
      </div>
    </div>
  </div>
);

export default Terms;
