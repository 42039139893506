/* eslint-disable */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert, Button, CardTitle, Col, FormGroup, Input, InputGroup, InputGroupAddon, Row} from 'reactstrap';
import {releaseDomain, requestDomainAuthCode, setDomainLoadingText, setDomainReleaseTAG, toggleDomainLoading} from '../../../actions/domain';
import {getRegistrar} from '../../../helpers/domains/domains';


class DomainRelease extends Component{

  handleReleaseTAG = (event) => {

    this.props.dispatch(setDomainReleaseTAG(event.target.value));
    
  };

  releaseTAG = () => {

    const {info, releaseTAG} = this.props.domain;
    this.props.dispatch(setDomainLoadingText('Submitting new TAG...'));
    this.props.dispatch(toggleDomainLoading());
    this.props.dispatch(releaseDomain(info.id, releaseTAG)).then(
      () => {

        this.props.dispatch(toggleDomainLoading());
            
      }
    );
    
  };

  requestAuthCode = () => {

    const {info} = this.props.domain;
    this.props.dispatch(setDomainLoadingText('Requesting AuthCode...'));
    this.props.dispatch(toggleDomainLoading());
    this.props.dispatch(requestDomainAuthCode(info.id)).then(
      () => {

        this.props.dispatch(toggleDomainLoading());
            
      }
    );
    
  };

  render() {

    const {info, releaseTAG, releaseSuccess} = this.props.domain;
    const registrar = getRegistrar(info.name);
    const cartItems = Object.keys(this.props.cart).length;
    const colWidth = cartItems ? 8 : 6;

    if(releaseSuccess){

      return (
                <>
                    <Row>
                        <Col>
                            <Alert color="success" className="text-center">Domain release successfully initiated</Alert>
                        </Col>
                    </Row>
                </>
      );

    }
    if(registrar === 'opensrs'){

      return (
                <>
                    <Row>
                        <Col md={colWidth} sm={12}>
                            <CardTitle>In order to release this domain from Syntura, first you need to request an authorisation code. </CardTitle>
                            <p>The 'Authcode' is a secret pass phrase that shows verifiable proof of ownership of your domain and will be sent to the domain's contact email address.
                                You will then need to provide this to your new registrar to initiate the release process</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="warning" onClick={this.requestAuthCode}>Request AuthCode</Button>
                        </Col>
                    </Row>
                </>
      );
        
    }
    if(registrar === 'nominet'){

      return (
                <>
                    <Row>
                        <Col md={colWidth} sm={12}>
                            <CardTitle>To release this domain to another registrar please provide its new TAG.</CardTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12}>
                            <FormGroup className="mt-3">
                                <InputGroup>
                                    <Input className="form-control" value={releaseTAG} onChange={this.handleReleaseTAG}/>
                                    <InputGroupAddon addonType="append">
                                        <Button color="warning" onClick={this.releaseTAG}>SUBMIT</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </>
      );
        
    }
        
    return <>
                <Row>
                    <Col md={colWidth} sm={12}>
                        <CardTitle>In order to transfer this domain out from Syntura, first you need to request an authorisation code. </CardTitle>
                        <p>The 'Authcode' is a secret pass phrase that shows verifiable proof of ownership of your domain and will be sent to the domain's contact email address.
                            You will then need to provide this to your new registrar to initiate the release process</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Alert color="danger">Well, this is embarrassing. We don't know how but this domain is a strange one. Please contact support if you see this message!</Alert>
                    </Col>
                </Row>
            </>;
        

  }

}


function mapStateToProps ({ domain, domainCart }) {

  return {
    domain,
    cart: domainCart
  };

}

export default connect(mapStateToProps)(DomainRelease);
