import * as React from 'react';
import Mail from '../../SvgIcons/Mail';
import Phone from '../../SvgIcons/Phone';

interface IProps {
  supportPhone? : string;
}

const SupportMailPhoneLinks = ({ supportPhone }: IProps) => (
    <>
      <a href="mailto:support@syntura.io">
        <Mail title="email" />
      </a>
      {supportPhone && (
      <a href={`tel:${supportPhone}`}>
        <Phone title="phone" />
      </a>
      )}

    </>
);
export default SupportMailPhoneLinks;
